import React from "react";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem
} from "shards-react";

import msg from "../../msg";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const FtmDetails = (props) => {
  const ftmDetails = props.ftmDetails[0];

  const isDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h2>{msg.confirmAlert.deleteFtm.title}</h2>
            <p>{msg.confirmAlert.deleteFtm.message}</p>
            <Button size="sm" theme="danger" className="mb-2 mr-1 float-right" onClick={onClose}>
              No
            </Button>
            <Button size="sm" theme="primary" className="mb-2 mr-1 float-right"
              onClick={()=> {
                props.deleteFtm();
                onClose();
              }}>
              Yes, Delete it!
            </Button>
          </div>
        );
      }
    });
  }

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src="https://myteam.myflobot.com/assets/images/User-Profile.png"
            alt="User-Profile"
            width="110"
          />
        </div>
        <h4 className="mb-0">{ftmDetails.firstName} {ftmDetails.lastName}</h4>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">
              Device Id : {ftmDetails.deviceId}
            </strong>

            <strong className="text-muted d-block mb-2">
              Group Id : {ftmDetails.groupId}
            </strong>
          </div>
          <Button size="sm" theme="danger" className="mb-2 mr-1 float-right" onClick={()=> {isDelete()}}>
            Delete
          </Button>
          <Button size="sm" theme="danger" className="mb-2 mr-1" onClick={()=> {props.goToFtmMapView(ftmDetails.deviceId)}}>
            Map
          </Button>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default FtmDetails;
