import React, { Component } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from 'react-google-maps';
class Map extends Component {
  state = {
    directions: null,
    origin: { lat: 40.756795, lng: -73.954298 },
    dest: { lat: 41.756795, lng: -78.954298 },
    waypts: [
      {
        location: { lat: 38.907192, lng: -77.036871, l: 'ccc' },
      },
      {
        location: { lat: 39.833851, lng: -74.871826, l: 'ddddd' },
      },
    ],
  };

  componentDidMount() {
    const directionsService = new window.google.maps.DirectionsService();

    const origin = { lat: 40.756795, lng: -73.954298 };
    const destination = { lat: 41.756795, lng: -78.954298 };
    //const waypt = [{lat: 38.907192,lng: -77.036871}]
    const waypts = [
      {
        location: { lat: 38.907192, lng: -77.036871 },
      },
    ];
    directionsService.route(
      {
        origin: this.state.origin,
        destination: this.state.dest,
        waypoints: this.state.waypts,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
        defaultZoom={13}
      >
        {this.state.directions != null && (
          <DirectionsRenderer
            directions={this.state.directions}
            options={{ suppressMarkers: true }}
          />
        )}
        <Marker position={this.state.origin} />
        <Marker position={this.state.dest} />
        {this.state.waypts.map((waypt) => (
          <Marker
            position={{ lat: waypt.location.lat, lng: waypt.location.lng }}
            icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}
            label={waypt.location.l}
          >
            <InfoWindow>
              <div>
                <div id="content">
                  <div id="siteNotice"></div>
                  <h5 id="firstHeading" className="firstHeading"></h5>
                  <div id="bodyContent">
                    <b>Device id: </b>
                    <br />
                    <b>Speed: </b>Kmh
                    <br />
                    <b>Date & {waypt.location.l} </b>
                    <br />
                  </div>
                </div>
              </div>
            </InfoWindow>{' '}
          </Marker>
        ))}
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `500px`, width: '500px' }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Map;
