import React from "react";
import { Row, Col, Form,  Button  } from "shards-react";
import { useForm } from "react-hook-form";
import SingleDatePicker from "../common/SingleDatePicker";

const FtmForm = (props) => {
  const { handleSubmit } = useForm();

  return(
    <Form onSubmit={handleSubmit(props.onSubmit)}>
      <Row className="py-2">
        <Col sm="6" className="d-flex mb-2 mb-sm-0">
          <SingleDatePicker
            getDate = {props.getDate}
          />
        </Col>

        <Col>
          <Button
            size="sm"
            className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
            theme="accent"
            type="submit"
          >
            View Map &rarr;
          </Button>
        </Col>

      </Row>
    </Form>
  );
}

export default FtmForm;
