import React,{useState} from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";

import { useForm } from "react-hook-form";


const AddUser = (props) => {
  const { handleSubmit } = useForm();
  const [newUserDetails, setNewUserDetails] = useState([]);

  const onSubmit = () => {
    props.submitNewUser(newUserDetails);
  };

  return(
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row form >
                <Col md="6" className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <FormInput
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    onChange={(event) => {setNewUserDetails({...newUserDetails,firstName:event.target.value})}}
                    // invalid= {newUserDetails.hasOwnProperty('firstName')}
                    required
                  />
                  {/* <FormFeedback >{msg.validation.addNewUser.firstNameIsRequired}</FormFeedback> */}

                </Col>
                <Col md="6">
                  <label htmlFor="lastName">Last Name</label>
                  <FormInput
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={(event) => {setNewUserDetails({...newUserDetails,lastName:event.target.value})}}
                    // invalid= {lastNameIsRequired}
                    required
                  />
                  {/* <FormFeedback >{msg.validation.addNewUser.lastNameIsRequired}</FormFeedback> */}
                </Col>
              </Row>

              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="email">Email</label>
                  <FormInput
                    id="email"
                    type="text"
                    placeholder="Email"
                    onChange={(event) => {setNewUserDetails({...newUserDetails,email:event.target.value})}}
                    // invalid= {emailIsRequired}
                    required
                  />
                  {/* <FormFeedback >{msg.validation.addNewUser.emailIsRequired}</FormFeedback> */}
                </Col>
                <Col md="6">
                  <label htmlFor="password">Password</label>
                  <FormInput
                    id="password"
                    type="password"
                    placeholder="Password"
                    onChange={(event) => {setNewUserDetails({...newUserDetails,password:event.target.value})}}
                    // invalid= {passwordIsRequired}
                    required
                  />
                  {/* <FormFeedback >{msg.validation.addNewUser.passwordIsRequired}</FormFeedback> */}
                </Col>
              </Row>

              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feInputState">User Level</label>
                  <FormSelect
                    id="userLevel"
                    onChange={(event) => {setNewUserDetails({...newUserDetails,userLevel:event.target.value})}}
                    // invalid= {userLevelIsRequired}
                    required
                  >
                    <option value="0">Select</option>
                    <option value="1">User</option>
                    <option value="2">Admin</option>
                    <option value="3">Super Admin</option>
                  </FormSelect>
                  {/* <FormFeedback >{msg.validation.addNewUser.userLevelIsRequired}</FormFeedback> */}
                </Col>
              </Row>
              <Button type="submit">Create New User</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default AddUser;
