import React from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import config from "../../config";
// import Loader from "../loader/Loader";

const MapView = (props) => {
  // const [isLoading, setIsLoading] = useState(true);

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: props.lat,
    lng: props.lon
  };

  const position = {
    lat: props.lat,
    lng: props.lon
  }

  const onLoad = marker => {
    console.log('marker: ', marker)
  }

  return (
    <>
      {/* {!isLoading ? ( */}
        <div>
          <Modal size="lg" open={props.open} toggle={props.toggle} position="center">
            <ModalHeader>Point Map</ModalHeader>
            <ModalBody>
              <LoadScript
                googleMapsApiKey="AIzaSyC0-NgIuNkMpKKoqZJTYsWTSjWlsV9t-Jo"
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={15}
                >
                  <Marker
                    onLoad={onLoad}
                    position={position}
                    icon={config.map_icon}
                  />
                </GoogleMap>
              </LoadScript>
            </ModalBody>
          </Modal>
        </div>
      {/* ):(
        <Loader/>
      )} */}
    </>
  );
}

export default MapView;
