import React,  { useEffect, useState} from "react";
import { Row, Col, Card, CardHeader, CardBody, Button, FormSelect } from "shards-react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import RangeDatePicker from "../common/RangeDatePicker";
import config from "../../config";

import LoaderTable from "../loader/LoaderTable";

import reportsController from "../../controllers/reportscontroller";
import MapView from "./MapView";

const SpeedingDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [speedingList, setSpeedingList] = useState([]);
  const [ftmsList, setFtmsList] = useState([]);
  const [tableData, setTableData] = useState();
  const [deviceId, setDeviceId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startFullDate, setStartFullDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endFullDate, setEndFullDate] = useState('');

  const columns = [
    {
      name: "GPS time",
      cell: row => <div>{new Date(row.datetime).toLocaleString()}</div>,
      selector: "datetime",
      sortable: true
    },
    {
      name: "Speed(Kph)",
      selector: "speed",
      sortable: true
    },
    {
      name: "Location",
      cell: row =>  <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => handleOpenModal(row.lat,row.lon)}>View</Button>,
      sortable: true
    }
  ];

  useEffect(() => {
    const ac = new AbortController();
    loadFtms();

    return () => ac.abort();
  }, []);

  const handleOpenModal = async (lat,lon) => {
    setLat(lat);
    setLon(lon);

    setOpen(true);
  }

  const getSpeedingDetails = async () => {
    setIsLoading(true);
    let response = await reportsController.getSpeedingDetails(deviceId,startDate,endDate);
    if(response.status === "Success"){
      let data = response.data
      setSpeedingList(data);
      setTableData({columns,data});
      setIsLoading(false);
    }
  }

  const loadSpeedingDetails = async () => {
    let is_load_speeding_details = await reportsController.loadParkingDetails(deviceId,startFullDate,endFullDate,startDate,endDate);

    if(is_load_speeding_details){
      getSpeedingDetails();
    }
  }

  const loadFtms = async () => {
    let response = await reportsController.loadFtms();
    if(response.status === "Success"){
      let data = response.data
      setFtmsList(data);
      setIsLoading(false);
    }
  }

  const handleDeviceId = (event) => {
    setDeviceId(event.target.value);
    console.log(event.target.value);
  }

  const getStartDate = (date_value) => {
    let date = config.apiDateConvert(date_value);
    setStartDate(date.apiDate);
    setStartFullDate(date.fullDateFormat);
  }

  const getEndDate = (date_value) => {
    let date = config.apiDateConvert(date_value);
    setEndDate(date.apiDate);
    setEndFullDate(date.fullDateFormat);
  }

  const toggle = () => {
    setOpen(!open);
  }

  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Speeding Details</h6>
      </CardHeader>
      {!isLoading ? (
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <FormSelect
                size="sm"
                id="ftm"
                style={{ maxWidth: "130px", marginRight: "10px" }}
                onChange={handleDeviceId}
              >
                <option value="">Please select Field Team Member</option>
                {ftmsList.map(ftm =>{
                  return <option value={ftm.deviceId} key={ftm.deviceId}>{ftm.firstName}</option>
                })}
              </FormSelect>
              <RangeDatePicker
                getStartDate = {getStartDate}
                getEndDate = {getEndDate}
              />

            </Col>
            <p style={{
              marginBottom: '0px',
              paddingLeft: '0px',
              marginLeft: '0px',
              color: 'red'
            }}>Maximum time range is 3 days.</p>
            <Col>
              <Button
                size="sm"
                className="d-flex btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
                onClick={() => loadSpeedingDetails()}
              >
                View Full Report &rarr;
              </Button>
            </Col>
          </Row>

            <div className="main">
              {/* <DataTableExtensions {...tableData}> */}
                <DataTable
                  columns={columns}
                  data={speedingList}
                  noHeader
                  pagination
                  // paginationServer
                  defaultSortField="datetime"
                  defaultSortAsc={false}
                  highlightOnHover
                />
              {/* </DataTableExtensions> */}
            </div>

            <MapView
              lat = {lat}
              lon = {lon}
              open = {open}
              toggle = {toggle}
            />

        </CardBody>
      ):(
        <LoaderTable/>
      )}
    </Card>
  );
}

export default SpeedingDetails;
