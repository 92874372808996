import React,{useState,useEffect} from "react";
import { Container, Row, Col, Card, CardBody, Button } from "shards-react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import PageTitle from "../components/common/PageTitle";

import Loader from "../components/loader/Loader";
import usersController from "../controllers/userscontroller";

const Users = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = usersController.columns;

  useEffect(() => {
    const ac = new AbortController();
    let isMounted = false;

    usersController.setHistory(history);

    const loadUsers = async () => {
      if (!isMounted) {
        let data = await usersController.loadUsers();
        setUsers(data);
        setTableData({columns,data});
        setIsLoading(false);
      }
    }

    loadUsers();

    return () => ac.abort();
  }, []);



  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Users" subtitle="Users List" className="text-sm-left" />
          <Button
            size="sm"
            theme="accent"
            className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
            style={{marginBottom:'15px'}}
            onClick={() => {history.push({
              pathname:'/add-new-user',
            })}}
          >Add User</Button>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="main">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={usersController.columns}
                      data={users}
                      noHeader
                      pagination
                      paginationServer
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      ):(
      <Loader/>
      )}
    </Container>
  );
}

export default Users;
