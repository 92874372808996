import React from "react";
import { store } from 'react-notifications-component';
import msg from "../msg";
import usersService from "../services/usersservice"
import {Button } from "shards-react";

let _history;

const usersController = {
   columns :[
    {
      name: "First Name",
      selector: "firstName",
      sortable: true
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true
    },
    {
      name: "Email",
      selector: "email",
      sortable: true
    },
    {
      name: "User Level",
      selector: "userLevel",
      sortable: true,
      cell: row => row.userLevel === 1 ? "User" : row.userLevel === 2 ? "Admin" : row.userLevel === 3 ? "Super Admin" : "N/A",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true
    },
    {
      cell: row => <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => usersController.handleViewGo(row._id)}>View</Button>
    }
  ],
  setHistory: (history) => {
    _history = history;
  },
  handleViewGo: (_id) => {
    _history.push({
      pathname:'/user-profile/'+_id,
      _id: _id,
      history: _history
    });
  },
  loadUsers: async () => {
    let data = await usersService.getUsers();
    if(data.status === "Success"){
      return data.data;
    }else{
      console.log(data.message);
    }
  },
  loadUserDetails: async (_id) => {
    let data = await usersService.getUserDetails(_id);
    if(data.status === "Success"){
      return data.data;
    }else{
      console.log(data.message);
    }
  },
  updateDetails: async (_id,details) => {
    let obj = {
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
    };

    let response = await usersService.updateUser(_id,obj);
    if(response.status === 'Success'){
      store.addNotification({
        title: msg.success.userDetailsUpdated.title,
        message: msg.success.userDetailsUpdated.message,
        type: msg.success.userDetailsUpdated.type,
        insert: msg.success.userDetailsUpdated.insert,
        container: msg.success.userDetailsUpdated.container,
        animationIn: msg.success.userDetailsUpdated.animationIn,
        animationOut: msg.success.userDetailsUpdated.animationOut,
        dismiss: {
          duration: msg.success.userDetailsUpdated.duration,
          onScreen: msg.success.userDetailsUpdated.onScreen
        }
      });
    }else{
      store.addNotification({
        title: msg.error.userDetailsUpdated.title,
        message: response.message,
        type: msg.error.userDetailsUpdated.type,
        insert: msg.error.userDetailsUpdated.insert,
        container: msg.error.userDetailsUpdated.container,
        animationIn: msg.error.userDetailsUpdated.animationIn,
        animationOut: msg.error.userDetailsUpdated.animationOut,
        dismiss: {
          duration: msg.error.userDetailsUpdated.duration,
          onScreen: msg.error.userDetailsUpdated.onScreen
        }
      });
    }
  },
  updatePassword: async (_id,passwords) => {
    if(passwords.password !== '' && passwords.newPassword !== ''){
      var obj_password = {
        password: passwords.password,
        newPassword: passwords.newPassword,
      };
      let resUpdatePassword = await usersService.updatePassword(_id,obj_password);

      if(resUpdatePassword.status === 'Success'){
        store.addNotification({
          title: msg.success.userPasswordUpdated.title,
          message: msg.success.userPasswordUpdated.message,
          type: msg.success.userPasswordUpdated.type,
          insert: msg.success.userPasswordUpdated.insert,
          container: msg.success.userPasswordUpdated.container,
          animationIn: msg.success.userPasswordUpdated.animationIn,
          animationOut: msg.success.userPasswordUpdated.animationOut,
          dismiss: {
            duration: msg.success.userPasswordUpdated.duration,
            onScreen: msg.success.userPasswordUpdated.onScreen
          }
        });
      }else{
        store.addNotification({
          title: msg.error.userPasswordUpdated.title,
          message: msg.error.userPasswordUpdated.message,
          type: msg.error.userPasswordUpdated.type,
          insert: msg.error.userPasswordUpdated.insert,
          container: msg.error.userPasswordUpdated.container,
          animationIn: msg.error.userPasswordUpdated.animationIn,
          animationOut: msg.error.userPasswordUpdated.animationOut,
          dismiss: {
            duration: msg.error.userPasswordUpdated.duration,
            onScreen: msg.error.userPasswordUpdated.onScreen
          }
        });
      }
    }else{
      store.addNotification({
        title: msg.error.userPasswordUpdated.title,
        message: "Please enter your password.",
        type: msg.error.userPasswordUpdated.type,
        insert: msg.error.userPasswordUpdated.insert,
        container: msg.error.userPasswordUpdated.container,
        animationIn: msg.error.userPasswordUpdated.animationIn,
        animationOut: msg.error.userPasswordUpdated.animationOut,
        dismiss: {
          duration: msg.error.userPasswordUpdated.duration,
          onScreen: msg.error.userPasswordUpdated.onScreen
        }
      });
    }
  },
  removeUser: async (userId,history) => {
    await usersService.deleteUser(userId);
    history.push({
      pathname:'/users'
    })
  }
}

export default usersController;
