import React from "react";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput
} from "shards-react";

function LoginView (props){

  const handleEmail = (event) => {
    props.setEmail(event.target.value);
  }

  const handlePassword = (event) => {
    props.setPassword(event.target.value);
  }

  const handle = () => console.log('Enter pressed');

  return(
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          src={require("./../../images/avatars/0.jpg")}
          alt="Sierra Brooks"
          width="110"
        />
      </div>
      {/* <h4 className="mb-0">Login</h4> */}
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
          <Row>
            <Col>
                <Row form>
                  {/* Email */}
                  <Col md="12" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder="Email Address"
                      onChange={handleEmail}
                      autoComplete="email"
                    />
                  </Col>
                  </Row>
                  <Row form>
                  {/* Password */}
                  <Col md="12" className="form-group">
                    <label htmlFor="fePassword">Password</label>
                    <FormInput
                      type="password"
                      id="fePassword"
                      placeholder="Password"
                      onChange={handlePassword}
                      autoComplete="current-password"
                    />
                  </Col>
                </Row>
                {/* <Button pill outline size="sm" className="mb-2" >
                  <i className="material-icons mr-1">person_add</i> Login
                </Button> */}
                <div className="col-md-12 bg-light text-right">
                  <button type="button" className="mb-2 btn btn-primary mr-2" onClick={() => props.sendLogin()} onKeyDown={e => e.key === 'Enter' && handle}>
                    <i className="material-icons mr-1">person_add</i> Login
                  </button>
                </div>
            </Col>
          </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
}

export default LoginView;
