import React,{useState} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import AddUser from "../components/components-overview/AddUser";

import config from "../config";
import msg from "../msg";
import Loader from "../components/loader/Loader";

import { store } from 'react-notifications-component';
import { decodeToken } from "react-jwt";

const AddNewUser = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submitNewUser = async (userDetails) => {
    setIsLoading(true);
    console.log(userDetails);

    let token = localStorage.getItem('token');
    const decodedToken = decodeToken(token);

    var obj = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      password: userDetails.password,
      userLevel: userDetails.userLevel,
      status:1,
      companiesId: decodedToken.companiesId
    }

    await fetch(config.api_url+'user/', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      },
      body: JSON.stringify(obj),
    })
    .then(response => response.json())
    .then(response => {
      console.log(response);
      if(response.status === "Success"){
        store.addNotification({
          title: msg.success.userDetailsAdd.title,
          message: msg.success.userDetailsAdd.message,
          type: msg.success.userDetailsAdd.type,
          insert: msg.success.userDetailsAdd.insert,
          container: msg.success.userDetailsAdd.container,
          animationIn: msg.success.userDetailsAdd.animationIn,
          animationOut: msg.success.userDetailsAdd.animationOut,
          dismiss: {
            duration: msg.success.userDetailsAdd.duration,
            onScreen: msg.success.userDetailsAdd.onScreen
          }
        });

        history.push({
          pathname:'/users'
        })
      }else{
        setIsLoading(false);
        store.addNotification({
          title: msg.error.userDetailsAdd.title,
          message: response.message,
          type: msg.error.userDetailsAdd.type,
          insert: msg.error.userDetailsAdd.insert,
          container: msg.error.userDetailsAdd.container,
          animationIn: msg.error.userDetailsAdd.animationIn,
          animationOut: msg.error.userDetailsAdd.animationOut,
          dismiss: {
            duration: msg.error.userDetailsAdd.duration,
            onScreen: msg.error.userDetailsAdd.onScreen
          }
        });
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Add New User" subtitle="Users" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <AddUser
              submitNewUser= {submitNewUser}
            />
          </Col>
        </Row>
      </>
      ):(
        <Loader/>
      )}
    </Container>
  );
};

export default AddNewUser;
