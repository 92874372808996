import React, { useEffect } from "react";
import config from "../config"
import { Container, Button } from "shards-react";

function Logout({ history }) {
  useEffect(() => {
    const ac = new AbortController();

    localStorage.clear();
    config.token = null;
    config.is_login = false;
    // history.push({
    //   pathname:'/login'
    // })

    return () => ac.abort();
  }, []);

  const goToLogin = () => {
    history.push({
      pathname:'/login'
    })
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>Logout</h2>
        <h3>You are Logout</h3>
        {/* <p>There was a problem on our end. Please try again later.</p> */}
        <Button pill onClick={() => goToLogin()}>&larr; Go Back</Button>
      </div>
    </div>
  </Container>
  );
}

export default Logout;
