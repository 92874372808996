import React from "react";
import {Button } from "shards-react";
import { store } from 'react-notifications-component';
import msg from "../msg";
import reportService from "../services/reportsservice";
import config from "../config";

const reportsController = {
  parkingColumns : [
    {
      name: "Start Time",
      selector: "start",
      sortable: true
    },
    {
      name: "End Time",
      selector: "end",
      sortable: true
    },
    {
      name: "Stop Duration (min)",
      selector: "stop_duration",
      sortable: true
    },
    {
      name: "Location",
      cell: row => <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => reportsController.handleOpenModal(row.lat,row.lon)}>View</Button>,
      sortable: true
    }
  ],
  handleOpenModal: (lat,lon) => {

  },
  getParkingDetails: async (deviceId,startDate,endDate) => {
    let data = await reportService.getParkingDetails(deviceId,startDate,endDate);
    return data;
  },
  loadParkingDetails: async (deviceId,startFullDate,endFullDate,startDate,endDate) => {
    let is_load_parking_details = false;

    if(deviceId === ''){
      store.addNotification({
        title: msg.error.deviceIdIsNull.title,
        message: msg.error.deviceIdIsNull.message,
        type: msg.error.deviceIdIsNull.type,
        insert: msg.error.deviceIdIsNull.insert,
        container: msg.error.deviceIdIsNull.container,
        animationIn: msg.error.deviceIdIsNull.animationIn,
        animationOut: msg.error.deviceIdIsNull.animationOut,
        dismiss: {
          duration: msg.error.deviceIdIsNull.duration,
          onScreen: msg.error.deviceIdIsNull.onScreen
        }
      });
    }else{
      let dateDiff = config.dateDiff(startFullDate,endFullDate);

      if(dateDiff.days > 4){
        store.addNotification({
          title: msg.error.maximumTimeRange.title,
          message: msg.error.maximumTimeRange.message,
          type: msg.error.maximumTimeRange.type,
          insert: msg.error.maximumTimeRange.insert,
          container: msg.error.maximumTimeRange.container,
          animationIn: msg.error.maximumTimeRange.animationIn,
          animationOut: msg.error.maximumTimeRange.animationOut,
          dismiss: {
            duration: msg.error.maximumTimeRange.duration,
            onScreen: msg.error.maximumTimeRange.onScreen
          }
        });
      }else{
        if(startDate === ""){
          store.addNotification({
            title: msg.error.startDateIsNull.title,
            message: msg.error.startDateIsNull.message,
            type: msg.error.startDateIsNull.type,
            insert: msg.error.startDateIsNull.insert,
            container: msg.error.startDateIsNull.container,
            animationIn: msg.error.startDateIsNull.animationIn,
            animationOut: msg.error.startDateIsNull.animationOut,
            dismiss: {
              duration: msg.error.startDateIsNull.duration,
              onScreen: msg.error.startDateIsNull.onScreen
            }
          });
        }else{
          if(endDate === ""){
            store.addNotification({
              title: msg.error.endDateIsNull.title,
              message: msg.error.endDateIsNull.message,
              type: msg.error.endDateIsNull.type,
              insert: msg.error.endDateIsNull.insert,
              container: msg.error.endDateIsNull.container,
              animationIn: msg.error.endDateIsNull.animationIn,
              animationOut: msg.error.endDateIsNull.animationOut,
              dismiss: {
                duration: msg.error.endDateIsNull.duration,
                onScreen: msg.error.endDateIsNull.onScreen
              }
            });
          }else{
            is_load_parking_details = true;
          }
        }
      }
    }

    return is_load_parking_details;
  },
  loadFtms: async () => {
    let data = await reportService.loadFtms();
    return data;
  },
  speedingColumns : [
    {
      name: "GPS time",
      cell: row => <div>{new Date(row.datetime).toLocaleString()}</div>,
      selector: "datetime",
      sortable: true
    },
    {
      name: "Speed(Kph)",
      selector: "speed",
      sortable: true
    },
    {
      name: "Location",
      cell: row => <div>{row.lat} {row.lon}</div>,
      sortable: true
    }
  ],
  getSpeedingDetails: async (deviceId,startDate,endDate) => {
    let data = await reportService.getSpeedingDetails(deviceId,startDate,endDate);
    return data;
  },
  loadSpeedingDetails: async (deviceId,startFullDate,endFullDate,startDate,endDate) => {
    let is_load_speeding_details = false;

    if(deviceId === ''){
      store.addNotification({
        title: msg.error.deviceIdIsNull.title,
        message: msg.error.deviceIdIsNull.message,
        type: msg.error.deviceIdIsNull.type,
        insert: msg.error.deviceIdIsNull.insert,
        container: msg.error.deviceIdIsNull.container,
        animationIn: msg.error.deviceIdIsNull.animationIn,
        animationOut: msg.error.deviceIdIsNull.animationOut,
        dismiss: {
          duration: msg.error.deviceIdIsNull.duration,
          onScreen: msg.error.deviceIdIsNull.onScreen
        }
      });
    }else{
      let dateDiff = config.dateDiff(startFullDate,endFullDate);

      if(dateDiff.days > 4){
        store.addNotification({
          title: msg.error.maximumTimeRange.title,
          message: msg.error.maximumTimeRange.message,
          type: msg.error.maximumTimeRange.type,
          insert: msg.error.maximumTimeRange.insert,
          container: msg.error.maximumTimeRange.container,
          animationIn: msg.error.maximumTimeRange.animationIn,
          animationOut: msg.error.maximumTimeRange.animationOut,
          dismiss: {
            duration: msg.error.maximumTimeRange.duration,
            onScreen: msg.error.maximumTimeRange.onScreen
          }
        });
      }else{
        if(startDate === "" && endDate === ""){
          store.addNotification({
            title: msg.error.dateIsNull.title,
            message: msg.error.dateIsNull.message,
            type: msg.error.dateIsNull.type,
            insert: msg.error.dateIsNull.insert,
            container: msg.error.dateIsNull.container,
            animationIn: msg.error.dateIsNull.animationIn,
            animationOut: msg.error.dateIsNull.animationOut,
            dismiss: {
              duration: msg.error.dateIsNull.duration,
              onScreen: msg.error.dateIsNull.onScreen
            }
          });
        }else{
          is_load_speeding_details = true;
        }
      }
    }

    return is_load_speeding_details;
  }
}

export default reportsController;
