import React,{useState} from "react";
import { Container, Row, Col } from "shards-react";
import { decodeToken } from "react-jwt";

import PageTitle from "../components/common/PageTitle";
import AddFtm from "../components/components-overview/AddFtm";

import config from "../config";
import msg from "../msg";
import Loader from "../components/loader/Loader";

import { store } from 'react-notifications-component';

import ftmController from "../controllers/ftmcontroller";

const AddNewFtm = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submitNewFtm = async (userDetails,files) => {
    setIsLoading(true);

    let flobotFTMId = parseInt(userDetails.flobot_ftm_id.replace(/[^0-9\.]+/g,""));
    let token = localStorage.getItem('token');
    const decodedToken = decodeToken(token);

    let obj = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      deviceId: userDetails.deviceId,
      flobot_ftm_id: flobotFTMId,
      mobile_number: userDetails.mobile_number,
      vehicle_number: userDetails.vehicle_number,
      userId: config.userId,
      groupId: 0,
      status:1,
      companiesId: decodedToken.companiesId,
      deviceImage: files
    }

    console.log(obj);

    setIsLoading(true);
    await ftmController.addNewFtm(obj,history);
    setIsLoading(false);
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Add New Ftm" subtitle="Ftms" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <AddFtm
              submitNewFtm= {submitNewFtm}
            />
          </Col>
        </Row>
      </>
      ):(
        <Loader/>
      )}
    </Container>
  );
};

export default AddNewFtm;
