import React,{useState,useEffect} from "react";
import { Container, Row, Col, Card, CardBody, CardHeader  } from "shards-react";
import { store } from 'react-notifications-component';
import { withScriptjs } from "react-google-maps";

import PageTitle from "../components/common/PageTitle";

import config from "../config";
import msg from "../msg";

import FtmForm from '../components/ftmmap/FtmForm';
import FtmMapView from '../components/ftmmap/FtmMapView';
import Map from '../components/ftmmap/Map';
import LocationTimeInterval from '../components/ftmmap/LocationTimeInterval';

import ftmsController from "../controllers/ftmcontroller";

const FtmMap = (props) => {
  const deviceId = props.match.params.deviceId;

  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultZoom
  } = props;


  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLocationTable, setIsLoadingLocationTable] = useState(true);
  const [locations, setLocations] = useState([]);
  const [timeInterval, setTimeInterval] = useState([]);
  const [mapDate, setMapDate] = useState(config.currentDate().apiDate);
  const [p, setP] = useState(1);

  const MapLoader = withScriptjs(Map);

  useEffect(() => {
    const ac = new AbortController();
    getFtmLocations(1);
    getFtmLocationTimeInterval();

    return () => ac.abort();
  }, []);

  const getFtmLocationTimeInterval = async () => {
    setIsLoadingLocationTable(true);
    let data = await ftmsController.loadFtmLocationTimeInterval(deviceId,mapDate);
    console.log(data);
    let locationsInterval = data;
    setTimeInterval(locationsInterval);
    if(locationsInterval.length > 0) {
      setIsLoadingLocationTable(false);
    }
  }

  const getFtmLocations = async (p) => {
    setIsLoading(true);
    let data = await ftmsController.loadFtmLocations(deviceId,mapDate,p);
    let locations = data;
    setLocations(locations);
    if(locations.length > 0) {
      setIsLoading(false);
    }
  }

  const getDate = (date_value) => {
    let date = config.apiDateConvert(date_value);
    let today = new Date();
    let selected_date = new Date(date.fullDateFormat);

    if(today.getTime() < selected_date.getTime()){
      store.addNotification({
        title: msg.error.futureDate.title,
        message: msg.error.futureDate.message,
        type: msg.error.futureDate.type,
        insert: msg.error.futureDate.insert,
        container: msg.error.futureDate.container,
        animationIn: msg.error.futureDate.animationIn,
        animationOut: msg.error.futureDate.animationOut,
        dismiss: {
          duration: msg.error.futureDate.duration,
          onScreen: msg.error.futureDate.onScreen
        }
      });
    }else{
      setMapDate(date.apiDate);
    }
  }

  const onSubmit = async () => {
    setP(1);
    getFtmLocations(1);
    getFtmLocationTimeInterval();
  }

  const moveMapPages = async (p) => {
    setP(p);
    getFtmLocations(p);
  }

  return(
    <Container fluid className="main-content-container px-4 pb-4">

        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Route History" subtitle="Field Team Members" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <Card>
              <CardHeader style={{backgroundColor: '#f9f9f9'}}>
                <Row>
                  <Col>
                    <FtmForm
                      onSubmit = {onSubmit}
                      getDate = {getDate}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <Row className="py-2">
                <FtmMapView
                  isLoading = {isLoading}
                  googleMapsApiKey = {process.env.REACT_APP_API_KEY}
                  locations = {locations}
                  loadingElement = {loadingElement}
                  containerElement = {containerElement}
                  mapElement = {mapElement}
                  defaultZoom = {defaultZoom}
                />
                {/* <MapLoader
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC0-NgIuNkMpKKoqZJTYsWTSjWlsV9t-Jo"
                  loadingElement={<div style={{ height: `100%` }} />}
                /> */}
                <Col sm="4">
                  <LocationTimeInterval
                    isLoadingLocationTable = {isLoadingLocationTable}
                    timeInterval = {timeInterval}
                    moveMapPages = {moveMapPages}
                    p = {p}
                  />
                </Col>
              </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </Container>
  );
}

export default FtmMap;
