import { decodeToken } from "react-jwt";

let token = localStorage.getItem('token');
const decodedToken = decodeToken(token);
console.log(decodedToken);

let userId = '';
let companiesId = '';
let firstName = '';
let lastName = '';

if(decodedToken !== null){
  userId = decodedToken.userId;
  companiesId = decodedToken.companiesId;
  firstName = decodedToken.firstName;
  lastName = decodedToken.lastName;
}

const data = {
  api_url: "https://myteambo.myflobot.com/api/",
  image_url: "https://myteambo.myflobot.com",
  socket_url: "wss://myteambo.myflobot.com",
  map_icon: "https://www.myflobot.com/GPSTracking/images/ftm.png",
  is_login: false,
  token: token,
  userId: userId,
  companiesId: companiesId,
  firstName: firstName,
  lastName: lastName,
  google_api_key: "AIzaSyC0-NgIuNkMpKKoqZJTYsWTSjWlsV9t-Jo",
  validationMsgColor: "#c4183c",
  dateConvert : (str) => {
    let date = new Date(str),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), month, day].join("-");
  },
  apiDateConvert : (str) => {
    let date = new Date(str),
    fullYear = date.getFullYear(),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    let fullDate = fullYear+''+month+''+day;

    let returnArr = {
      fullDateFormat : [date.getFullYear(), month, day].join("-"),
      fullDate : fullDate,
      apiDate : fullDate.substring(2),
    };

    return returnArr;
  },
  currentDate: () => {
    let date = new Date(),
    fullYear = date.getFullYear(),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    let fullDate = fullYear+''+month+''+day;

    let returnArr = {
      fullDateFormat : [date.getFullYear(), month, day].join("-"),
      fullDate : fullDate,
      apiDate : fullDate.substring(2),
      fullYear : fullYear
    };

    return returnArr;
  },
  dateDiff: (dateStr1,dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");

    let returnArr = {
      milliseconds : diffTime,
      days : diffDays,
    };

    return returnArr;
  },
  viewDateTimeFormat: (str) => {
    let dateFormat = new Date(str);
    let date = ("0" + dateFormat.getDate()).slice(-2);
    let month = ("0" + (dateFormat.getMonth() + 1)).slice(-2);
    let year = dateFormat.getFullYear();
    let hours = dateFormat.getHours();
    let minutes = dateFormat.getMinutes();
    let seconds = dateFormat.getSeconds();

    return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  },
  reportsTypeList: [
    {
      title: "Parking Details",
      value: "19,291"
    },
    {
      title: "Speeding details",
      value: "11,201"
    }
  ]
}

export default data;
