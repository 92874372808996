import config from "../config";
import { decodeToken } from "react-jwt";

const usersService = {
  getUsers : () => {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem('token');
      const decodedToken = decodeToken(token);

      let companiesId = '';

      if(decodedToken !== null){
        companiesId = decodedToken.companiesId;
      }

      fetch(config.api_url+'users/companies/'+companiesId, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response)
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getUserDetails : (_id) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'user/'+_id, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
          resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  updateUser : (_id,obj) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'user/'+_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(obj),
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  updatePassword : (_id,obj) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'user/password/'+_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(obj),
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  deleteUser : (userId) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'user/'+userId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error)
      });
    });
  }
}

export default usersService;
