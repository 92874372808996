import React from "react";
import { store } from 'react-notifications-component';
import msg from "../msg";
import ftmsService from "../services/ftmsservice"
import {Button } from "shards-react";

let _history;

const ftmController = {
  columns :[
    {
      name: "First Name",
      selector: "firstName",
      sortable: true
    },
    {
      name: "Last Name",
      selector: "lastName",
      sortable: true
    },
    {
      name: "Device Id",
      selector: "deviceId",
      sortable: true
    },
    {
      name: "Flobot FTM Id",
      selector: "flobot_ftm_id",
      sortable: true,
      cell: row => row.flobot_ftm_id !== undefined ? "MTF"+row.flobot_ftm_id : "N/A",
    },
    {
      name: "Mobile Number",
      selector: "mobile_number",
      sortable: true,
      cell: row => row.mobile_number !== undefined ? row.mobile_number : "N/A",
    },
    {
      name: "Vehicle Number",
      selector: "vehicle_number",
      sortable: true,
      cell: row => row.vehicle_number !== undefined ? row.vehicle_number : "N/A",
    },
    {
      name: "Group Id",
      selector: "groupId",
      sortable: true
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: row => row.status === 1 ? "SHOW": "HIDE",
    },
    {
      cell: row => <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={() => ftmController.handleViewGo(row._id)}>View</Button>
    }
  ],
  setHistory: (history) => {
    _history = history;
  },
  handleViewGo: (_id) => {
    _history.push({
      pathname:'/ftm-profile/'+_id,
      _id: _id,
      history: _history
    });
  },
  loadFtms: async () => {
    let data = await ftmsService.getFtms();
    if(data.status === "Success"){
      return data.data;
    }else{
      console.log(data.message);
    }
  },
  loadFtmDetails: async (_id) => {
    let data = await ftmsService.getFtmDetails(_id);
    if(data.status === "Success"){
      return data.data;
    }else{
      console.log(data.message);
    }
  },
  updateDetails: async (_id,details) => {
    // console.log(details.flobot_ftm_id);
    let flobotFTMId = parseInt(details.flobot_ftm_id);

    let obj = {
      firstName: details.firstName,
      lastName: details.lastName,
      deviceId: details.deviceId,
      flobot_ftm_id: flobotFTMId,
      status: details.status,
      mobile_number: details.mobile_number
    };

    console.log(obj);

    let response = await ftmsService.updateFtm(_id,obj);
    if(response.status === 'Success'){
      store.addNotification({
        title: msg.success.ftmDetailsUpdated.title,
        message: msg.success.ftmDetailsUpdated.message,
        type: msg.success.ftmDetailsUpdated.type,
        insert: msg.success.ftmDetailsUpdated.insert,
        container: msg.success.ftmDetailsUpdated.container,
        animationIn: msg.success.ftmDetailsUpdated.animationIn,
        animationOut: msg.success.ftmDetailsUpdated.animationOut,
        dismiss: {
          duration: msg.success.ftmDetailsUpdated.duration,
          onScreen: msg.success.ftmDetailsUpdated.onScreen
        }
      });
    }else{
      store.addNotification({
        title: msg.error.ftmDetailsUpdated.title,
        message: response.message,
        type: msg.error.ftmDetailsUpdated.type,
        insert: msg.error.ftmDetailsUpdated.insert,
        container: msg.error.ftmDetailsUpdated.container,
        animationIn: msg.error.ftmDetailsUpdated.animationIn,
        animationOut: msg.error.ftmDetailsUpdated.animationOut,
        dismiss: {
          duration: msg.error.ftmDetailsUpdated.duration,
          onScreen: msg.error.ftmDetailsUpdated.onScreen
        }
      });
    }
  },
  removeFtm: async (ftmId,history) => {
    await ftmsService.deleteFtm(ftmId);
    history.push({
      pathname:'/ftms'
    })
  },
  goToFtmMap: (deviceId,history) => {
    history.push({
      pathname:'/ftm-map/'+deviceId
    })
  },
  loadFtmLocationTimeInterval: async (deviceId,mapDate) => {
    let data = await ftmsService.getFtmLocationTimeInterval(deviceId,mapDate);

    if(data.status === "Success"){
      let locationsInterval = data.data;
      if(locationsInterval.length === 0) {
        store.addNotification({
          title: msg.error.notAvailableLocationPoints.title,
          message: msg.error.notAvailableLocationPoints.message,
          type: msg.error.notAvailableLocationPoints.type,
          insert: msg.error.notAvailableLocationPoints.insert,
          container: msg.error.notAvailableLocationPoints.container,
          animationIn: msg.error.notAvailableLocationPoints.animationIn,
          animationOut: msg.error.notAvailableLocationPoints.animationOut,
          dismiss: {
            duration: msg.error.notAvailableLocationPoints.duration,
            onScreen: msg.error.notAvailableLocationPoints.onScreen
          }
        });
      }
      return data.data;
    }else{
      console.log(data.message);
    }
  },
  loadFtmLocations : async (deviceId,mapDate,p) => {
    let data = await ftmsService.getFtmLocations(deviceId,mapDate,p);
    if(data.status === "Success"){
      let locations = data.data;
      if(locations.length === 0) {
        store.addNotification({
          title: msg.error.notAvailableLocationPoints.title,
          message: msg.error.notAvailableLocationPoints.message,
          type: msg.error.notAvailableLocationPoints.type,
          insert: msg.error.notAvailableLocationPoints.insert,
          container: msg.error.notAvailableLocationPoints.container,
          animationIn: msg.error.notAvailableLocationPoints.animationIn,
          animationOut: msg.error.notAvailableLocationPoints.animationOut,
          dismiss: {
            duration: msg.error.notAvailableLocationPoints.duration,
            onScreen: msg.error.notAvailableLocationPoints.onScreen
          }
        });
      }

      return data.data;
    }else{
      console.log(data.message);
    }
  },
  addNewFtm: async (ftm_obj,history) => {
    let data = await ftmsService.addFtms(ftm_obj);
    if(data.status === "Success"){
      store.addNotification({
        title: msg.success.ftmDetailsAdd.title,
        message: msg.success.ftmDetailsAdd.message,
        type: msg.success.ftmDetailsAdd.type,
        insert: msg.success.ftmDetailsAdd.insert,
        container: msg.success.ftmDetailsAdd.container,
        animationIn: msg.success.ftmDetailsAdd.animationIn,
        animationOut: msg.success.ftmDetailsAdd.animationOut,
        dismiss: {
          duration: msg.success.ftmDetailsAdd.duration,
          onScreen: msg.success.ftmDetailsAdd.onScreen
        }
      });

      history.push({
        pathname:'/ftms'
      })
    }else{
      store.addNotification({
        title: msg.error.ftmDetailsAdd.title,
        message: data.message,
        type: msg.error.ftmDetailsAdd.type,
        insert: msg.error.ftmDetailsAdd.insert,
        container: msg.error.ftmDetailsAdd.container,
        animationIn: msg.error.ftmDetailsAdd.animationIn,
        animationOut: msg.error.ftmDetailsAdd.animationOut,
        dismiss: {
          duration: msg.error.ftmDetailsAdd.duration,
          onScreen: msg.error.ftmDetailsAdd.onScreen
        }
      });
    }
  }
}

export default ftmController;
