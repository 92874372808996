import React from "react";
import { Card, CardBody, ListGroup,  CardHeader } from "shards-react";
import LoaderTable from "../loader/LoaderTable";

import config from "../../config"

const LocationTimeInterval = (props) => {
  return(
    <Card small>
      <CardHeader className="border-bottom">
        <h6 className="m-0"><b>Location Time Interval</b></h6>
        <div className="block-handle" />
      </CardHeader>

      <CardBody className="p-0">
        <ListGroup small flush className="list-group-small">
          {!props.isLoadingLocationTable ? (
            <table className="table mb-0 table-hover" style={{cursor: "pointer", height: "110px", overflow: "auto"}}>
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Start
                  </th>
                  <th scope="col" className="border-0">
                    End
                  </th>
                  {/* <th scope="col" className="border-0">
                    Time
                  </th> */}
                </tr>
              </thead>
              <tbody>
              {props.timeInterval.map((item, idx) => (
                <tr onClick={() => props.moveMapPages(idx+1)} style = {{color: props.p === idx+1 ? '#2a9d27' : null}} key={idx}>
                  <td>{idx+1}</td>
                  <td>{item.start_location_address.compound_code}<br/>{config.viewDateTimeFormat(item.start.datetime)}</td>
                  <td>{item.end_location_address.compound_code}<br/>{config.viewDateTimeFormat(item.end.datetime)}</td>
                  {/* <td>{item.time_interval}</td> */}
                </tr>
              ))}
              </tbody>
            </table>
          ):(
            <div style={{marginTop: '2px',marginBottom: '10px'}}>
              <LoaderTable/>
            </div>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
}

export default LocationTimeInterval;
