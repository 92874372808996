import React,{useState} from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
} from "shards-react";

import FileBase64 from 'react-file-base64';
import { useForm } from "react-hook-form";


const AddFtm = (props) => {
  const {  handleSubmit } = useForm();
  const [newFtmDetails, setNewFtmDetails] = useState([]);
  const [files, setFiles] = useState([]);

  const onSubmit = () => {
    props.submitNewFtm(newFtmDetails,files);
  };

  const getFiles = (files) => {
    setFiles(files);
    console.log(files);
  }

  return(
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row form >
                <Col md="4" className="form-group">
                  <label htmlFor="firstName">First Name</label>
                  <FormInput
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,firstName:event.target.value})}}
                    // invalid= {newUserDetails.hasOwnProperty('firstName')}
                    required
                  />
                </Col>
                <Col md="4">
                  <label htmlFor="lastName">Last Name</label>
                  <FormInput
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,lastName:event.target.value})}}
                    // invalid= {lastNameIsRequired}
                    required
                  />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="deviceId">Device Id</label>
                  <FormInput
                    id="deviceId"
                    type="text"
                    placeholder="Device Id"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,deviceId:event.target.value})}}
                    // invalid= {emailIsRequired}
                    required
                  />
                </Col>
              </Row>

              <Row form >
                <Col md="4" className="form-group">
                  <label htmlFor="flobotFtmId">Flobot FTM id</label>
                  <FormInput
                    id="deviceId"
                    type="text"
                    placeholder="Flobot FTM id"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,flobot_ftm_id:event.target.value})}}
                    // invalid= {emailIsRequired}
                    required
                  />
                </Col>

                <Col md="4" className="form-group">
                  <label htmlFor="mobile_number">Mobile Number</label>
                  <FormInput
                    id="mobile_number"
                    type="text"
                    placeholder="Mobile Number"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,mobile_number:event.target.value})}}
                    // invalid= {emailIsRequired}
                    required
                  />
                </Col>

                <Col md="4" className="form-group">
                  <label htmlFor="vehicle_number">Vehicle Number</label>
                  <FormInput
                    id="vehicle_number"
                    type="text"
                    placeholder="Vehicle Number"
                    onChange={(event) => {setNewFtmDetails({...newFtmDetails,vehicle_number:event.target.value})}}
                    // invalid= {emailIsRequired}
                    required
                  />
                </Col>
              </Row>

              <Row form >
                <Col md="4" className="form-group">
                  <label htmlFor="deviceImage">Device Image</label>
                  <FileBase64
                    id="deviceImage"
                    // type="file"
                    placeholder="Device Image"
                    multiple={ true }
                    // onChange={(event) => {setNewFtmDetails({...newFtmDetails,deviceImage:event.target.files[0]})}}
                    onDone={getFiles}
                    // invalid= {newUserDetails.hasOwnProperty('firstName')}
                    required
                  />
                </Col>
              </Row>


              <Button type="submit">Create New User</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default AddFtm;
