import dashboardService from "../services/dashboardservice"

const dashboardController = {
  dashboardDetails : async () => {
    let data = await dashboardService.getDashboardDetails();

    if(data.status === "Success"){
     return data.data;
    }else{
      console.log(data.message);
    }
  }
}

export default dashboardController;
