import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import LoginView from "../components/login/LoginView";

import loginController from "../controllers/logincontroller"
import loginservice from "../services/loginservice"

import { store } from 'react-notifications-component';
import msg from "../msg";

import Dashboard from "./Dashboard"

function Login({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendLogin = async () => {
    let isMounted = false;

    if (!isMounted) {
      setIsLoading(true);
      await loginservice.login(email, password).then((data)=> {
        setIsLoading(false);
        if(data.status === "Success"){
          localStorage.setItem('token', data.token);
          history.push({
            pathname:'/dashboard'
          })
          // setIsShowDashboard(true);
        }else{
          store.addNotification({
            title: msg.error.login.title,
            message: msg.error.login.message,
            type: msg.error.login.type,
            insert: msg.error.login.insert,
            container: msg.error.login.container,
            animationIn: msg.error.login.animationIn,
            animationOut: msg.error.login.animationOut,
            dismiss: {
              duration: msg.error.login.duration,
              onScreen: msg.error.login.onScreen
            }
          });
        }
      });

    }

    return () => {
      isMounted = true;
    };
  }

  return(
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="My Flobot" subtitle="www.freshmilksoftware.com" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="6" style={{marginLeft: window.innerWidth > 400 ? "25%" : ""}}>
          {!isLoading ?(
          <LoginView
            setEmail= {setEmail}
            setPassword= {setPassword}
            sendLogin= {sendLogin}
          />
          ):(
          <div className="d-flex justify-content-center" style={{marginTop: "30%"}}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
