import config from "../config";

const loginService = {
  login : (email,password) => {
    return new Promise(async (resolve, reject) => {
      let loginData = {}
      loginData["email"] = email;
      loginData["password"] = password;

      fetch(config.api_url+'login', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData),
      })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  }
}

export default loginService;
