import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button,
  FormCheckbox
} from "shards-react";

const FtmAccountDetails = (props) => {
  const ftmDetails = props.ftmDetails[0];
  const [userUpdateDetails, setUserUpdateDetails] = useState(props.ftmDetails[0]);

  const handleUpdate = () => {
    // console.log(userUpdateDetails);
    props.updateFtmDetails(props._id,userUpdateDetails);
  }

  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Details</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            {/* <Form> */}
              <Row form>
                {/* First Name */}
                <Col md="4" className="form-group">
                  <label htmlFor="feFirstName">First Name</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="First Name"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,firstName:event.target.value})}}
                    defaultValue={ftmDetails.firstName}
                  />
                </Col>
                {/* Last Name */}
                <Col md="4" className="form-group">
                  <label htmlFor="feLastName">Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,lastName:event.target.value})}}
                    defaultValue={ftmDetails.lastName}
                  />
                </Col>

                <Col md="4" className="form-group">
                  <label htmlFor="feEmail">Device Id</label>
                  <FormInput
                    id="feDeviceId"
                    placeholder="Device Id"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,deviceId:event.target.value})}}
                    defaultValue={ftmDetails.deviceId}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <label htmlFor="flobotFtmId">Flobot FTM id</label>
                  <FormInput
                    id="feFlobotFtmId"
                    placeholder="Flobot FTM id"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,flobot_ftm_id:event.target.value})}}
                    defaultValue={ftmDetails.flobot_ftm_id !== undefined ? "MTF"+ftmDetails.flobot_ftm_id : ""}
                  />
                </Col>

                <Col md="4" className="form-group">
                  <label htmlFor="mobile_number">Mobile Number</label>
                  <FormInput
                    id="mobile_number"
                    placeholder="Mobile Number"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,mobile_number:event.target.value})}}
                    defaultValue={ftmDetails.mobile_number !== undefined ? ftmDetails.mobile_number : ""}
                  />
                </Col>

                <Col md="4" className="form-group">
                  <label htmlFor="vehicle_number">Vehicle Number</label>
                  <FormInput
                    id="vehicle_number"
                    placeholder="Vehicle Number"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,vehicle_number:event.target.value})}}
                    defaultValue={ftmDetails.vehicle_number !== undefined ? ftmDetails.vehicle_number : ""}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="2" className="form-group">
                  <FormCheckbox defaultChecked={ftmDetails.status === 1 ? true : false} onChange={(event) =>{setUserUpdateDetails({...userUpdateDetails,status:event.target.checked ? 1 : 0})}}>Show in map</FormCheckbox>
                </Col>
              </Row>


              <Button theme="accent" onClick={() => {handleUpdate()}}>Update Account</Button>
            {/* </Form> */}
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

FtmAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

FtmAccountDetails.defaultProps = {
  title: "Account Details"
};

export default FtmAccountDetails;
