import React,{useState} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import ParkingDetails from "./../components/reports/ParkingDetails";
import ReportsType from "./../components/common/ReportsType";
import SpeedingDetails from "./../components/reports/SpeedingDetails";
import MapView from "./../components/reports/MapView";


const Reports = () => {
  const [reportsType, setReportsType] = useState('Parking Details');

  const updateReportTypes = (type) => {
    setReportsType(type);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Reports" subtitle="Report List" className="text-sm-left mb-3" />
      </Row>

      <Row>
        <Col lg="3" md="12" sm="12" className="mb-4">
          <ReportsType
            updateReportTypes = {updateReportTypes}
            reportsType = {reportsType}
          />
        </Col>

        <Col lg="9" md="12" sm="12" className="mb-4">
          {reportsType === "Parking Details" ? <ParkingDetails /> : null}
          {reportsType === "Speeding details" ? <SpeedingDetails /> : null}
        </Col>

        <MapView/>
      </Row>
    </Container>
  );
};

export default Reports;
