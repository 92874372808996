import React, { useState,useEffect } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

import mapController from "../../controllers/mapcontroller";
import Loader from "../loader/Loader";
import config from "../../config";
import { io } from "socket.io-client";

// const markers = [];

let token = localStorage.getItem('token');
const socket = io.connect(config.socket_url, {
  query: {token},
});

socket.on('currentStatus', function (data){
  console.log(data)
});

function Map(props) {
  // const markers = [];
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // setDBmarkers();
    let isMounted = false;
    const ac = new AbortController();
    const setDBmarkers = async () => {
      if (!isMounted) {
        let db_locations = await mapController.loadFtmLastLocations();

        let id_count = 0;
        for (const location of db_locations) {
          if(location.status == 1){
            let obj = {
              id: id_count,
              device_id: location.device_id,
              name: location.firstName+" "+location.lastName,
              speed: location.speed,
              datetime: location.datetime,
              position: { lat: location.lat, lng: location.lon}
            }
            id_count++;
            markers.push(obj);
          }
        }

        setIsLoading(false);
      }
    }

    setDBmarkers();

    return () => ac.abort();
  },[]);

  useEffect(() => {
    const ac = new AbortController();
    socket.on('location_list', async function(list) {
      // data.map( (list) => {
        console.log(list);
        if(list.status == 1){
          const index = markers.findIndex(
            (element) => element.device_id === list.device_id
          );

          if(index !== -1) {
            markers[index]["speed"] = list.speed;
            markers[index]["datetime"] = list.datetime;
            markers[index]["position"] = { lat: list.lat, lng: list.lon};
          }else{
            let obj = {
              id: list._id,
              device_id: list.device_id,
              name: list.firstName+" "+list.lastName,
              speed: list.speed,
              datetime: list.datetime,
              position: { lat: list.lat, lng: list.lon}
            }
            markers.push(obj);
          }
          let newCount = count+1;
          setCount(newCount);
        }
      // });
        return () => ac.abort();
    });
  },[count]);

  // const setDBmarkers = async () => {
  //   let db_locations = await mapController.loadFtmLastLocations();

  //   let id_count = 0;
  //   for (const location of db_locations) {
  //     let obj = {
  //       id: id_count,
  //       device_id: location.device_id,
  //       name: location.firstName+" "+location.lastName,
  //       speed: location.speed,
  //       datetime: location.datetime,
  //       position: { lat: location.lat, lng: location.lon}
  //     }
  //     id_count++;
  //     markers.push(obj);
  //   }

  //   setIsLoading(false);
  // }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  let map_width = window.innerWidth - (window.innerWidth === 1920 ? 420 : 350);
  if(window.innerWidth < 400){
    map_width = ""
  }


  return (
    <>
    {!isLoading ? (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: props.width, height: props.height }}
    >
      {markers.map(({ id, device_id, name, speed, datetime, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
          icon={config.map_icon}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <div id="content">
                  <div id="siteNotice"></div>
                  <h5 id="firstHeading" className="firstHeading">{name}</h5>
                  <div id="bodyContent">
                    <b>Device id: </b>{device_id}<br/>
                    <b>Speed: </b>{speed} Kmh<br/>
                    <b>Date & time: </b>{datetime}<br/>
                  </div>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
    ):(
      <Loader/>
    )}
    </>
  );
}

export default Map;
