import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import { LoginLayout } from "./loginLayouts";

// Route Views
import UserProfile from "./views/UserProfile";
import AddNewPost from "./views/AddNewPost";
import Map from "./views/Map";
import Login from "./views/Login";
import Logout from "./views/Logout";
import Users from "./views/Users";
import AddNewUser from "./views/AddNewUser";
import Dashboard from "./views/Dashboard";

import FTMS from "./views/Ftms";
import FtmProfile from "./views/FtmProfile";
import AddNewFtm from "./views/AddNewFtm";
import FtmMap from "./views/FtmMap";
import Reports from "./views/Reports";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/user-profile/:_id",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/map",
    layout: DefaultLayout,
    component: Map
  },
  {
    path: "/login",
    layout: LoginLayout,
    component: Login
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/add-new-user",
    layout: DefaultLayout,
    component: AddNewUser
  },
  {
    path: "/ftms",
    layout: DefaultLayout,
    component: FTMS
  },
  {
    path: "/ftm-profile/:_id",
    layout: DefaultLayout,
    component: FtmProfile
  },
  {
    path: "/add-new-ftm",
    layout: DefaultLayout,
    component: AddNewFtm
  },
  {
    path: "/ftm-map/:deviceId",
    layout: DefaultLayout,
    component: FtmMap
  },
  {
    path: "/reports",
    layout: DefaultLayout,
    component: Reports
  },
  {
    path: "/logout",
    layout: LoginLayout,
    component: Logout
  }
];
