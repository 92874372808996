import config from "../config";

const loginService = {
  getFtmLastLocations : () => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'ftm-locations', {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  }
}

export default loginService;
