const msg = {
  success : {
    userDetailsUpdated : {
      title: "Successfully updated",
      message: "User details successfully updated.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    userPasswordUpdated : {
      title: "Successfully updated",
      message: "User password successfully updated.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    userDetailsAdd : {
      title: "Successfully save",
      message: "User details successfully save.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    ftmDetailsAdd : {
      title: "Successfully save",
      message: "Field Team Member details successfully save.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    ftmDetailsUpdated : {
      title: "Successfully updated",
      message: "Field Team Member details successfully updated.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
  },
  error : {
    userDetailsUpdated : {
      title: "Details updated unsuccessfully",
      message: "User details unsuccessfully updated.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    userPasswordUpdated : {
      title: "Password updated unsuccessfully",
      message: "Current password is incorrect.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    userDetailsAdd : {
      title: "Successfully not save",
      message: "User details unsuccessfully save.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    ftmDetailsAdd : {
      title: "Successfully not save",
      message: "Field Team Member details unsuccessfully save.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    ftmDetailsUpdated : {
      title: "Details updated unsuccessfully",
      message: "Field Team Member details unsuccessfully updated.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    deviceIdIsNull:{
      title: "Please select the Field Team Member.",
      message: "Please select Field Team Member",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    dateIsNull:{
      title: "Date can not be null",
      message: "Please select date",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    startDateIsNull:{
      title: "Please select the start date",
      message: "Start date can not be null",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    endDateIsNull:{
      title: "Please select the end date",
      message: "End date can not be null",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    maximumTimeRange : {
      title: "Maximum time range exceeded",
      message: "Maximum time range is 3 days.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    futureDate : {
      title: "Incorrect date",
      message: "Can not added further date",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    notAvailableLocationPoints : {
      title: "Not available",
      message: "Not available location points",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    },
    login : {
      title: "Error",
      message: "Your email or password is incorrect.",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      duration: 5000,
      onScreen: true
    }
  },
  confirmAlert: {
    deleteUser : {
      title: "Are you sure?",
      message: "You want to delete this user?",
    },
    deleteFtm : {
      title: "Are you sure?",
      message: "You want to delete this ftm?",
    }
  },
  validation: {
    addNewUser :{
      firstNameIsRequired: "First Name is required",
      lastNameIsRequired: "Last Name is required",
      emailIsRequired: "Email is required",
      emailPattern: "Email is type",
      passwordIsRequired: "Password is required",
      userLevelIsRequired: "User Level is required"
    }
  }
}

export default msg;
