import React, {useState} from "react";
import { Col  } from "shards-react";
import Map from '../map/Map';
import LoaderTable from "../loader/LoaderTable";

const FtmMapView = (props) => {
  return(
    <Col sm="8" >
      {!props.isLoading ? (
      <Map
        googleMapURL={
          'https://maps.googleapis.com/maps/api/js?key=' +
          props.googleMapsApiKey +
          '&libraries=geometry,drawing,places'
        }
        markers={props.locations}
        loadingElement={props.loadingElement || <div style={{height: `100%`}}/>}
        containerElement={props.containerElement || <div style={{height: "100%", minHeight: '500px'}}/>}
        mapElement={props.mapElement || <div style={{height: `100%`}}/>}
        // defaultCenter={defaultCenter || {lat: 25.798939, lng: -80.291409}}
        defaultZoom={props.defaultZoom || 11}
      />
      ):(
        <div style={{marginTop: '45px',marginBottom: '10px'}}>
          <LoaderTable/>
        </div>
      )}
    </Col>
  );
}

export default FtmMapView;

