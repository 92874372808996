import React from 'react';
import { LoadScript } from "@react-google-maps/api";
import Map from "../components/map/googleMap";
import config from "../config";

function GoogleMap() {
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: config.google_api_key // Add your API key
  // });

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyC0-NgIuNkMpKKoqZJTYsWTSjWlsV9t-Jo"
    >
      <Map
        width = "100%"
        height = "728%"
      />
    </LoadScript>
  );
}

export default GoogleMap
