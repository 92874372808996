import mapService from "../services/mapservice";

const mapController = {
  loadFtmLastLocations : async () => {
    let data = await mapService.getFtmLastLocations();
    if(data.status === "Success"){
      return data.data;
    }else{
      console.log(data.message);
    }
  }
}

export default mapController;
