export default function() {
  return [
    // {
    //   title: "Blog Dashboard",
    //   to: "/blog-overview",
    //   htmlBefore: '<i class="material-icons">edit</i>',
    //   htmlAfter: ""
    // },
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // },
    {
      title: "Map",
      htmlBefore: '<i class="material-icons">map</i>',
      to: "/map",
    },
    // {
    //   title: "Login",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/login",
    // },
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/users",
    },
    {
      title: "Field Team Members",
      htmlBefore: '<i class="material-icons">directions_car</i>',
      to: "/ftms",
    },
    {
      title: "Reports",
      htmlBefore: '<i class="material-icons">file_copy</i>',
      to: "/reports",
    }
  ];
}
