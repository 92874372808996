import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem
} from "shards-react";

import config from "../../config"

const ReportsType = (props) => {

  const handleReportsType = (title) => {
    props.updateReportTypes(title);
  }

  return(
    <Card small>
      <CardHeader className="border-bottom">
        <h6 className="m-0">Reports</h6>
        <div className="block-handle" />
      </CardHeader>

      <CardBody className="p-0">
        <ListGroup small flush className="list-group-small">
          {config.reportsTypeList.map((item, idx) => (
            <ListGroupItem key={idx} className="d-flex px-3" onClick={() => handleReportsType(item.title)} style={{backgroundColor: props.reportsType === item.title ? "#e8e8e8" : "", cursor: "pointer"}}>
              <span className="text-semibold text-fiord-blue">{item.title}</span>
              <span className="ml-auto text-right text-semibold text-reagent-gray"></span>
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>

      {/* <CardFooter className="border-top">
        <Row>
          <Col>
            <FormSelect
              size="sm"
              value="last-week"
              style={{ maxWidth: "130px" }}
              onChange={() => {}}
            >
              <option value="last-week">Last Week</option>
              <option value="today">Today</option>
              <option value="last-month">Last Month</option>
              <option value="last-year">Last Year</option>
            </FormSelect>
          </Col>

          <Col className="text-right view-report">
            <a href="#">Full report &rarr;</a>
          </Col>
        </Row>
      </CardFooter> */}
    </Card>
  );
};

export default ReportsType;
