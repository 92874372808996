/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  DirectionsRenderer,
  InfoWindow,
  Marker
} from "react-google-maps";

class MapDirectionsRenderer extends React.Component {
  state = {
    directions: null,
    error: null
  };

  componentDidMount() {
    const { places, travelMode } = this.props;
    const directionsService = new google.maps.DirectionsService();

    const waypts = places.map(p =>({
      location: {lat: p.latitude, lng:p.longitude},
      stopover: true
    }));

    const origin = waypts.shift().location;
    const destination = waypts.pop().location;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypts,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>;
    }
    return (this.state.directions && <DirectionsRenderer directions={this.state.directions} options={{ suppressMarkers: true }} />)
  }
}

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultCenter={props.defaultCenter}
      defaultZoom={props.defaultZoom}
    >
      {/* {props.markers.map((marker, index) => {
        const position = { lat: marker.latitude, lng: marker.longitude };
        return <Marker key={index} position={position} />;
      })} */}
      <MapDirectionsRenderer
        places={props.markers}
        travelMode={google.maps.TravelMode.WALKING}
      />

        {props.markers.map((marker) => (
          <Marker
            position={{ lat: marker.latitude, lng: marker.longitude }}
            // icon={'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'}
            // label={waypt.location.l}
          >
            <InfoWindow >
              <div>
                <div id="content">
                  <div id="bodyContent">
                    <b>{marker.datetime}</b>
                  </div>
                </div>
              </div>
            </InfoWindow>
          </Marker>
        ))}
    </GoogleMap>
  ))
);

export default Map;
