import React, {useEffect, useState} from "react";
import { Container, Row, Col, Card, CardBody, CardImg} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import FtmDetails from "../components/ftm-profile/FtmDetails";
import FtmAccountDetails from "../components/ftm-profile/FtmAccountDetails";

import config from "../config";
import Loader from "../components/loader/Loader";

import ftmsController from "../controllers/ftmcontroller";

const UserProfile = (props) => {
  const [ftmId, setFtmId] = useState([]);
  const [history, setHistory] = useState();
  const [ftmDetails, setFtmDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const ac = new AbortController();
    setHistory(props.history);
    const _id = props.match.params._id;
    setFtmId(_id);
    loadFtmDetails(_id);

    return () => ac.abort();
  }, []);

  const loadFtmDetails = async (_id) => {
    let data = await ftmsController.loadFtmDetails(_id);
    setFtmDetails(data);
    setIsLoading(false);
  }

  const updateFtmDetails = async (_id,details) => {
    setIsLoading(true);
    // console.log(details);
    await ftmsController.updateDetails(_id,details);
    loadFtmDetails(_id);
  }

  const deleteFtm = async () => {
    await ftmsController.removeFtm(ftmId,history);
  }

  const goToFtmMapView = (deviceId) => {
    ftmsController.goToFtmMap(deviceId,history);
  }

  return(
    <Container fluid className="main-content-container px-4">
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle title="Field Team Member Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="12">
            <FtmDetails
              ftmDetails = {ftmDetails}
              deleteFtm= {deleteFtm}
              goToFtmMapView= {goToFtmMapView}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={ftmDetails[0].imageName === undefined ? "12" : "8"}>
            <FtmAccountDetails
              _id = {ftmId}
              ftmDetails = {ftmDetails}
              updateFtmDetails = {updateFtmDetails}
            />
          </Col>
          {ftmDetails[0].imageName !== undefined ? (
          <Col lg="4">
            <Card>
              <CardImg top src={config.image_url+"/device-image/"+ftmDetails[0].imageName} />
              <CardBody>
                <p>Device image.</p>
                {/* <Button size="sm" theme="danger" className="mb-2 mr-1 float-right">
                Yes, Delete it!
                </Button> */}
              </CardBody>
            </Card>
          </Col>
          ) : null}
        </Row>
      </>
      ):(
        <Loader/>
      )}
    </Container>
  )
};

export default UserProfile;
