import { store } from 'react-notifications-component';
import msg from "../msg";
import loginService from "../services/loginservice"

const loginController = {
  loginSubmit : async (email,password,history) => {
    let data = await loginService.login(email,password);
    localStorage.setItem('token', data.token);

    if(data.status === "Success"){
      history.push({
        pathname:'/dashboard'
      })
    }else{
      store.addNotification({
        title: msg.error.login.title,
        message: msg.error.login.message,
        type: msg.error.login.type,
        insert: msg.error.login.insert,
        container: msg.error.login.container,
        animationIn: msg.error.login.animationIn,
        animationOut: msg.error.login.animationOut,
        dismiss: {
          duration: msg.error.login.duration,
          onScreen: msg.error.login.onScreen
        }
      });
    }
  }
}

export default loginController;
