import React,{useState,useEffect} from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "shards-react";

import Loader from "../components/loader/Loader";

import PageTitle from "./../components/common/PageTitle";
import dashboardController from "../controllers/dashboardcontroller";
// import DashboardMap from "../components/map/dashboardMap"
import Map from "../components/map/googleMap";
import { LoadScript } from "@react-google-maps/api";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardDetails, setDashboardDetails] = useState(true);

  let map_width = window.innerWidth - (window.innerWidth === 1920 ? 420 : 350);
  if(window.innerWidth < 400){
    map_width = ""
  }

  useEffect(() => {
    let isMounted = false;
    const ac = new AbortController();
    const loadDashboardDetails = async () => {
      if (!isMounted) {
        setIsLoading(true)
        let data = await dashboardController.dashboardDetails();
        console.log(data);
        setDashboardDetails(data);
        setIsLoading(false);
      }
    }

    loadDashboardDetails();

    return () => ac.abort();
  }, []);



  return(
    <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Overview" subtitle="Dashboard" className="text-sm-left mb-3" />
    </Row>
    {!isLoading ? (
    <>
    {/* Small Stats Blocks */}
    <Row>
      {dashboardDetails.map((details,index) => (
        <Col className="col-lg mb-4" key={index}>
          <Card>
            <CardBody>
              <CardTitle>{details.label}</CardTitle>
              {details.value}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
    {/* {isLoaded ? <Map /> : null} */}
    <Card>
      <CardBody >
        <CardTitle>Live Map</CardTitle>
          {/* <DashboardMap/> */}
          <LoadScript
            googleMapsApiKey="AIzaSyC0-NgIuNkMpKKoqZJTYsWTSjWlsV9t-Jo"
          >
            <Map
              width = {map_width}
              height = "400px"
            />
          </LoadScript>
      </CardBody>
    </Card>
    </>
    ):(
      <div>
        <Loader/>
      </div>
    )}
  </Container>
  );
};

export default Dashboard;
