import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button
} from "shards-react";

const UserAccountDetails = (props) => {
  const userDetails = props.userDetails[0];
  const [userUpdateDetails, setUserUpdateDetails] = useState(props.userDetails[0]);

  const handleUpdate = () => {
    props.updateUserDetails(props._id,userUpdateDetails);
  }

  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Details</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            {/* <Form> */}
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">First Name</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="First Name"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,firstName:event.target.value})}}
                    defaultValue={userDetails.firstName}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feLastName">Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,lastName:event.target.value})}}
                    defaultValue={userDetails.lastName}
                  />
                </Col>
              </Row>
              <Row form>
                {/* Email */}
                <Col md="12" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    id="feEmail"
                    placeholder="Email Address"
                    onChange={(event) => {setUserUpdateDetails({...userUpdateDetails,email:event.target.value})}}
                    autoComplete="email"
                    defaultValue={userDetails.email}
                  />
                </Col>
              </Row>


              <Button theme="accent" onClick={() => {handleUpdate()}}>Update Account</Button>
            {/* </Form> */}
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
