import { decodeToken } from "react-jwt";
import config from "../config";

const ftmsService = {
  addFtms : (ftm_obj) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'ftm', {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(ftm_obj),
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getFtms : () => {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem('token');
      const decodedToken = decodeToken(token);

      fetch(config.api_url+'ftms/'+decodedToken.companiesId, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getFtmDetails: async (_id) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'ftm/'+_id, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  updateFtm: (_id,obj) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'ftm/'+_id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
        body: JSON.stringify(obj),
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  deleteFtm: async (ftmId) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'ftm/'+ftmId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getFtmLocationTimeInterval: async (deviceId,mapDate) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'get-ftm-location-time-interval/'+deviceId+'/'+mapDate, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getFtmLocations : async (deviceId,mapDate,p) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'get-ftm-location/'+deviceId+'/'+mapDate+'/'+p, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  }

}

export default ftmsService;
