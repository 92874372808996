import React,{useState,useEffect} from "react";
import { Container, Row, Col, Card, CardBody, Button } from "shards-react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import PageTitle from "../components/common/PageTitle";

import Loader from "../components/loader/Loader";
import ftmsController from "../controllers/ftmcontroller";

const Ftms = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = ftmsController.columns;

  useEffect(() => {
    const ac = new AbortController();

    ftmsController.setHistory(history);
    loadFtms();

    return () => ac.abort();
  }, []);

  const loadFtms = async () => {
    let data = await ftmsController.loadFtms();
    setUsers(data);
    setTableData({columns,data});
    setIsLoading(false);
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Field Team Member" subtitle="Field Team Member List" className="text-sm-left" />
          <Button
            size="sm"
            theme="accent"
            className="d-flex ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0"
            style={{marginBottom:'15px'}}
            onClick={() => {history.push({
              pathname:'/add-new-ftm',
            })}}
          >Add Ftm</Button>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="main">
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={columns}
                      data={users}
                      noHeader
                      pagination
                      // paginationServer
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </DataTableExtensions>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
      ):(
      <Loader/>
      )}
    </Container>
  );
}

// export default Users;

// import React, { useState, useEffect, useCallback, useMemo } from "react";
// import axios from "axios";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";

// const removeItem = (array, item) => {
//   const newArray = array.slice();
//   newArray.splice(newArray.findIndex(a => a === item), 1);

//   return newArray;
// };

// const Users = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [totalRows, setTotalRows] = useState(0);
//   const [perPage, setPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);
//   // const [deleted, setDeleted] = useState([]);

//   const fetchUsers = async (page, size = perPage) => {
//     setLoading(true);

//     const response = await axios.get(
//       `https://reqres.in/api/users?page=${page}&per_page=${size}&delay=1`
//     );

//     setData(response.data.data);
//     setTotalRows(response.data.total);
//     setLoading(false);
//   };

//   useEffect(() => {
//     fetchUsers(1);
//   }, []);

//   const handleDelete = useCallback(
//     row => async () => {
//       await axios.delete(`https://reqres.in/api/users/${row.id}`);
//       const response = await axios.get(
//         `https://reqres.in/api/users?page=${currentPage}&per_page=${perPage}`
//       );

//       setData(removeItem(response.data.data, row));
//       setTotalRows(totalRows - 1);
//     },
//     [currentPage, perPage, totalRows]
//   );

//   const columns = useMemo(
//     () => [
//       {
//         name: "First Name",
//         selector: "first_name",
//         sortable: true
//       },
//       {
//         name: "Last Name",
//         selector: "last_name",
//         sortable: true
//       },
//       {
//         name: "Email",
//         selector: "email",
//         sortable: true
//       },
//       {
//         // eslint-disable-next-line react/button-has-type
//         cell: row => <button onClick={handleDelete(row)}>Delete</button>
//       }
//     ],
//     [handleDelete]
//   );

//   const handlePageChange = page => {
//     fetchUsers(page);
//     setCurrentPage(page);
//   };

//   const handlePerRowsChange = async (newPerPage, page) => {
//     fetchUsers(page, newPerPage);
//     setPerPage(newPerPage);
//   };

//   return (
//     <Container fluid className="main-content-container px-4">
//       <Row noGutters className="page-header py-4">
//         <PageTitle sm="4" title="Add New Post" subtitle="Blog Posts" className="text-sm-left" />
//       </Row>
//         <Row>
//         <Col>
//           <Card small className="mb-4">
//             {/* <CardHeader className="border-bottom">
//               <h6 className="m-0">Active Users</h6>
//             </CardHeader> */}
//             <CardBody className="p-0 pb-3">
//               <DataTable
//                 // title="Users"
//                 columns={columns}
//                 data={data}
//                 progressPending={loading}
//                 pagination
//                 paginationServer
//                 paginationTotalRows={totalRows}
//                 paginationDefaultPage={currentPage}
//                 onChangeRowsPerPage={handlePerRowsChange}
//                 onChangePage={handlePageChange}
//                 selectableRows
//                 onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)}
//               />
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// }

export default Ftms;
