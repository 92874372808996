import React, {useState} from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Button
} from "shards-react";

const UserAccountDetails = (props) => {
  const [passwordDetails, setPasswordDetails] = useState({password: '',newPassword: ''});

  const handleUpdate = () => {
    props.updateUserPassword(props._id,passwordDetails);
  }

  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Password</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
              {/* <Form> */}

              <Row form>
                {/* Email */}
                <Col md="6" className="form-group">
                  <label htmlFor="feCurrentPassword">Current Password</label>
                  <FormInput
                    type="password"
                    id="feCurrentPassword"
                    placeholder="Password"
                    onChange={(event) => {setPasswordDetails({...passwordDetails,password:event.target.value})}}
                    autoComplete="password"
                    defaultValue=""
                  />
                </Col>
                {/* Password */}
                <Col md="6" className="form-group">
                  <label htmlFor="fePassword">New Password</label>
                  <FormInput
                    type="password"
                    id="feNewPassword"
                    placeholder="New Password"
                    defaultValue=""
                    onChange={(event) => {setPasswordDetails({...passwordDetails,newPassword:event.target.value})}}
                    autoComplete="current-password"
                  />
                </Col>
              </Row>

              <Button theme="accent" onClick={() => {handleUpdate()}}>Update</Button>
            {/* </Form> */}
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  )
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
