import { decodeToken } from "react-jwt";
import config from "../config";

const reportsService = {
  getParkingDetails: (deviceId,startDate,endDate) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'parking-details/'+deviceId+'/'+startDate+'/'+endDate, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  getSpeedingDetails: (deviceId,startDate,endDate) => {
    return new Promise(async (resolve, reject) => {
      fetch(config.api_url+'speeding-details/'+deviceId+'/'+startDate+'/'+endDate, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  },
  loadFtms: async () => {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem('token');
      const decodedToken = decodeToken(token);

      fetch(config.api_url+'ftms/'+decodedToken.companiesId, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token')
        },
      })
      .then(response => response.json())
      .then(response => {
        resolve(response);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
    });
  }
}

export default reportsService;
