import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import UserPassword from "../components/user-profile-lite/UserPassword";

import Loader from "../components/loader/Loader";

import usersController from "../controllers/userscontroller";

const UserProfile = (props) => {
  const [userId, setUserId] = useState([]);
  const [history, setHistory] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    let isMounted = false;
    const ac = new AbortController();

    setHistory(props.history);
    let _id = props.match.params._id;
    setUserId(_id);

    const loadUserDetails = async (_id) => {
      if (!isMounted) {
        let data = await usersController.loadUserDetails(_id);
        setUserDetails(data);
        setIsLoading(false);
      }
    }

    loadUserDetails(_id);

    return () => ac.abort();
  }, [isUpdate]);

  const updateUserDetails = async (_id,details) => {
    setIsLoading(true);
    setIsUpdate(true);
    await usersController.updateDetails(_id,details);
    setIsUpdate(false);
  }

  const updateUserPassword = async (_id,passwords) => {
    setIsLoading(true);
    setIsUpdate(true);
    await usersController.updatePassword(_id,passwords);
    setIsUpdate(false);
  }

  const deleteUser = async () => {
    await usersController.removeUser(userId,history);
  }

  return(
    <Container fluid className="main-content-container px-4">
      {!isLoading ? (
      <>
        <Row noGutters className="page-header py-4">
          <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="12">
            <UserDetails
              userDetails = {userDetails}
              deleteUser= {deleteUser}
            />
          </Col>
          <Col lg="12">
            <UserAccountDetails
              _id = {userId}
              userDetails = {userDetails}
              updateUserDetails = {updateUserDetails}
            />
          </Col>
          <Col lg="12">
            <UserPassword
              _id = {userId}
              userDetails = {userDetails}
              updateUserPassword = {updateUserPassword}
            />
          </Col>
        </Row>
      </>
      ):(
        <Loader/>
      )}
    </Container>
  )
};

export default UserProfile;
